<template>
  <div class="video-view bg-gradient-to-r from-black to-yellow-600 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <div ref="videoContainer" class="overflow-hidden p-8">
        <YouTubeVideo />
      </div>
    </div>
  </div>
</template>

<script>
import YouTubeVideo from '@/components/YouTubeVideo.vue'

export default {
  name: 'VideoView',
  components: {
    YouTubeVideo
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.videoContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }
}
</script>
