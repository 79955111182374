<template>
  <div class="youtube-video">
    <iframe
      ref="videoIframe"
      width="100%"
      height="100%"
      :src="videoSrc"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
      class="rounded-xl shadow-md"
      @loadedmetadata="handleLoadedMetadata"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'YouTubeVideo',
  data() {
    return {
      videoSrc: "https://www.youtube.com/embed/Q83SJPQtFvI?autoplay=1&controls=1&showinfo=0&rel=0&loop=0&playlist=Q83SJPQtFvI&mute=0"
    };
  },
  methods: {
    handleLoadedMetadata() {
      const iframe = this.$refs.videoIframe;
      iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    },
    handleOrientationChange() {
      if (window.orientation === 90 || window.orientation === -90) {
        const iframe = this.$refs.videoIframe;
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen();
        }
      }
    }
  },
  mounted() {
    window.addEventListener('orientationchange', this.handleOrientationChange);
  },
  beforeUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  }
}
</script>

<style scoped>
.youtube-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}
.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 640px) {
  .youtube-video {
    padding-bottom: 75%; /* Adjust aspect ratio for mobile portrait */
  }
}
</style>
