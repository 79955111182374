<template>
    <div class="privacy-policy bg-gradient-to-r from-black to-blue-600 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-7xl mx-auto">
        <h2 class="text-4xl font-bold text-white mb-8">Privacy Policy: Protect Ya Neck (and Your Data)</h2>
        <div class="bg-white rounded-xl shadow-md overflow-hidden p-8">
          <p class="text-gray-600 mb-6">Wu-Tang Financial is committed to protecting your privacy like we protect our Wu-Tang sword style. This Privacy Policy explains how we collect, use, and safeguard your personal information, because cash rules everything around me, privacy included.</p>
          
          <h3 class="text-2xl font-bold text-gray-900 mb-4">Information Collection: The Mystery of Chessboxin'</h3>
          <p class="text-gray-600 mb-6">We collect information you provide directly to us when using our services, like a true Wu-Tang disciple collecting knowledge from the 36 chambers.</p>
          
          <h3 class="text-2xl font-bold text-gray-900 mb-4">Use of Information: Method Man-agement</h3>
          <p class="text-gray-600 mb-6">We use the information we collect to provide, maintain, and improve our services, ensuring our financial advice is as sharp as the Liquid Swords.</p>
          
          <h3 class="text-2xl font-bold text-gray-900 mb-4">Information Sharing: The Wu-Tang Clan Ain't Nuthing ta F' Wit</h3>
          <p class="text-gray-600 mb-6">We do not share your personal information with third parties except as described in this policy. Your secrets are safer than the Wu-Tang secret album.</p>
          
          <h3 class="text-2xl font-bold text-gray-900 mb-4">Security: Enter the 36 Chambers of Data Protection</h3>
          <p class="text-gray-600 mb-6">We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access. Our security is tighter than Ol' Dirty Bastard's rhymes.</p>
          
          <h3 class="text-2xl font-bold text-gray-900 mb-4">Google Analytics: C.R.E.A.M. (Cookies Rule Everything Around Me)</h3>
          <p class="text-gray-600 mb-6">We use Google Analytics to improve our website and services. This means Google may process your data to provide us with insights, but don't worry, they're not trying to bring da ruckus to your privacy. By using our website, you consent to the processing of data about you by Google in the manner and for the purposes set out in our Cookie Policy. Remember, you can always opt-out of Google Analytics if you want to keep your data as mysterious as the Wu-Tang Clan's secret handshake.</p>

          <h3 class="text-2xl font-bold text-gray-900 mb-4">Parody Disclaimer: The Triumph of Humor</h3>
          <p class="text-gray-600 mb-6">
            Wu-Tang Financial is a work of parody, as fictional as the Shaolin style. This website is not a real financial institution and is in no way endorsed by or affiliated with the Wu-Tang Clan. Any resemblance to real persons, living or dead, or actual financial advice is purely coincidental. We're just here to bring da motherfuckin' ruckus to the world of imaginary finance.
          </p>
          <p class="text-gray-600 mb-6">
            Remember, our financial advice is about as real as a Killa Bee's sting, and our investment strategies are as fictional as the 36 Chambers. We're just trying to C.R.E.A.M. (Create Really Entertaining And Amusing Material). So don't take our advice seriously, unless you want your wallet to end up as empty as ODB's liquor bottle.
          </p>
          <p class="text-gray-600 mb-6">
            In the words of the great GZA, "Those who play with toys can't hang with the boys." So if you're looking for real financial advice, you might want to protect ya neck and diversify your bonds somewhere else, son!
          </p>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  }
  </script>
  