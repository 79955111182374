<template>
  <nav class="wu-nav">
    <div class="wu-brand">
      <h1 class="wu-title">{{ title }}</h1>
      <p class="wu-slogan">{{ slogan }}</p>
    </div>
    <ul>
      <li v-for="(item, index) in menuItems" :key="index">
        <router-link :to="item.path" class="animate__animated animate__fadeIn" :style="{ animationDelay: `${index * 0.1}s` }">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'NavigationMenu',
  data() {
    return {
      title: 'Wu-Tang Financial',
      slogan: 'Cash rules everything around me',
      menuItems: [
        { name: 'Home', path: '/' },
        { name: 'Introduction', path: '/video' },
        { name: 'Services', path: '/services' },
        { name: 'About\u00A0Us', path: '/about' },
        { name: 'Careers', path: '/careers' },
        { name: 'Contact', path: '/contact' }
      ]
    }
  }
}
</script>
<style scoped>
.wu-nav {
  background: linear-gradient(90deg, var(--wu-black), var(--wu-gray));
  padding: 15px 0;
  border-bottom: 3px solid var(--wu-yellow);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wu-brand {
  margin-left: 20px;
}

.wu-nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.wu-nav ul li {
  margin: 0 15px;
}
.wu-nav ul li a {
  text-decoration: none;
  color: var(--wu-white);
  font-weight: bold;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  white-space: nowrap;
}
.wu-nav ul li a:hover {
  background-color: var(--wu-yellow);
  color: var(--wu-black);
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .wu-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .wu-nav ul li {
    margin: 10px 0;
  }
}
</style>
