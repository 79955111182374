import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import ServicesPage from '@/views/ServicesPage.vue'
import AboutPage from '@/views/AboutPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import CareersPage from '@/views/CareersPage.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import VideoView from '@/views/VideoView.vue'

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/services', component: ServicesPage },
  { path: '/about', component: AboutPage },
  { path: '/contact', component: ContactPage },
  { path: '/careers', component: CareersPage },
  { path: '/video', name: 'Video', component: VideoView }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router