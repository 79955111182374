<template>
  <div class="services bg-gradient-to-r from-black to-green-600 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <h2 class="text-4xl font-bold text-white mb-8">Enter the 36 Chambers of Financial Planning</h2>
      <p class="text-xl text-gray-300 mb-12">Wu-Tang Financial offers a diverse array of services to protect and grow your wealth.</p>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="service in services" :key="service.title" class="bg-white rounded-xl shadow-md overflow-hidden">
          <div class="p-8">
            <h3 class="text-2xl font-bold text-gray-900 mb-4">{{ service.title }}</h3>
            <p class="text-gray-600 mb-4">{{ service.description }}</p>
            <h4 class="text-xl font-semibold text-gray-800 mb-2">Key Features:</h4>
            <ul class="list-disc list-inside text-gray-600">
              <li v-for="feature in service.features" :key="feature">{{ feature }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesPage',
  data() {
    return {
      services: [
        {
          title: 'C.R.E.A.M. (Cash Rules Everything Around Me) Budgeting',
          description: 'Master your cash flow with our comprehensive budgeting strategies.',
          features: [
            'Personalized budget planning',
            'Expense tracking tools',
            'Cash flow optimization',
            'Debt reduction strategies'
          ]
        },
        {
          title: 'Protect Ya Neck: Risk Management Strategies',
          description: 'Safeguard your financial future with our robust risk management solutions.',
          features: [
            'Comprehensive insurance analysis',
            'Asset protection strategies',
            'Emergency fund planning',
            'Liability management'
          ]
        },
        {
          title: 'Diversify Ya Bonds: Investment Portfolio Management',
          description: 'Maximize returns while minimizing risk through strategic diversification.',
          features: [
            'Custom portfolio construction',
            'Asset allocation strategies',
            'Regular portfolio rebalancing',
            'Tax-efficient investing'
          ]
        },
        {
          title: 'Bring Da Ruckus: Aggressive Growth Strategies',
          description: 'Accelerate your wealth accumulation with our high-growth investment approaches.',
          features: [
            'High-yield investment opportunities',
            'Emerging market analysis',
            'Alternative investment strategies',
            'Risk-adjusted return optimization'
          ]
        },
        {
          title: 'The Mystery of Chessboxin\': Retirement Planning',
          description: 'Navigate the complexities of retirement planning with our expert guidance.',
          features: [
            'Retirement income projections',
            'Social Security optimization',
            'Long-term care planning',
            'Estate planning strategies'
          ]
        }
      ]
    }
  }
}
</script>