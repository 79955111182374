<template>
  <section class="relative py-16 lg:py-24 bg-gradient-to-r from-black to-yellow-600">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="grid max-w-lg grid-cols-1 mx-auto lg:max-w-full lg:items-center lg:grid-cols-2 gap-y-12 lg:gap-x-16">
        <div class="text-center lg:text-left">
          <h1 class="text-4xl font-bold leading-tight text-white sm:text-5xl sm:leading-tight lg:leading-tight lg:text-6xl font-pj animate__animated animate__fadeInLeft">Cash Rules Everything Around Me: Understanding Market Dynamics</h1>
          <p class="mt-6 text-xl text-gray-200 sm:mt-8 font-inter animate__animated animate__fadeInUp">Welcome to Wu-Tang Financial, where we help you master the art of money.</p>
          <div class="mt-8 animate__animated animate__fadeInUp animate__delay-1s">
            <router-link to="/video" class="px-8 py-4 text-lg font-semibold text-white transition-all duration-200 bg-yellow-500 rounded-full hover:bg-yellow-600">Get Started</router-link>
          </div>
        </div>
        <div class="animate__animated animate__fadeInRight">
          <img class="wu-hero-image" src="images/wu-tang-advisor.jpg" alt="Wu-Tang Financial Advisor" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>
