<template>
    <div class="careers bg-gradient-to-r from-black to-blue-600 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-7xl mx-auto">
        <h2 class="text-4xl font-bold text-white mb-8">Join the Wu-Tang Financial Clan</h2>
        <p class="text-xl text-gray-300 mb-12">We're always looking for talented individuals to bring the ruckus to the financial world.</p>
        
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div v-for="job in jobListings" :key="job.title" class="bg-white rounded-xl shadow-md overflow-hidden">
            <div class="p-8">
              <h3 class="text-2xl font-bold text-gray-900 mb-4">{{ job.title }}</h3>
              <p class="text-gray-600 mb-4">{{ job.description }}</p>
              <h4 class="text-xl font-semibold text-gray-800 mb-2">Qualifications:</h4>
              <ul class="list-disc list-inside text-gray-600">
                <li v-for="qualification in job.qualifications" :key="qualification">{{ qualification }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'CareersPage',
  data() {
    return {
      jobListings: [
        {
          title: 'Disruptive Innovation Specialist',
          description: 'We\'re seeking a visionary individual to revolutionize the financial industry with cutting-edge ideas and strategies. As ODB once said, "Wu-Tang is for the children," and so is our approach to innovative finance.',
          qualifications: [
            'Proven track record of thinking outside the boombox',
            'Ability to freestyle innovative solutions to complex problems',
            'Strong understanding of both financial markets and street economics',
            'Experience with blockchain technology and cryptocurrency',
            'Willingness to challenge the status quo and bring the ruckus to traditional finance',
            'Proficiency in creating financial models that are "shimmy shimmy ya, shimmy yam, shimmy yay"',
            'Ability to navigate the financial world like it\'s the Brooklyn Zoo',
            'Expertise in turning "raw" data into "cooked" insights',
            'Commitment to keeping it "Ason Unique" in all financial strategies',
          ]
        },
        {
          title: 'Golden Arms Investment Analyst',
          description: 'We\'re looking for a sharp-minded individual with golden intuition to analyze and execute high-impact investment strategies.',
          qualifications: [
            'Ability to spot golden opportunities in the market with razor-sharp precision',
            'Experience in crafting investment strategies that hit as hard as kung-fu strikes',
            'Proficiency in financial modeling and data analysis, with a focus on "golden" ratios',
            'Strong communication skills to articulate complex financial concepts in street-smart terms',
            'Adaptability to rapidly changing market conditions, like switching up flows in a cypher',
            'Expertise in risk management, ensuring our investments are as solid as a 36-chamber fortress',
            'Commitment to maintaining the Wu-Tang Financial code of ethics and client confidentiality',
            'Ability to work collaboratively in a clan-like environment while also shining individually'
          ]
        },
        {
          title: 'Verbal Intercourse Specialist',
          description: 'Seeking a master of communication to lead our client relations and negotiation strategies. As Cappadonna would say, we need someone who can "activate the leave no witnesses mic device."',
          qualifications: [
            'Exceptional verbal and written communication skills',
            'Ability to navigate complex financial discussions with ease and charisma',
            'Experience in crafting persuasive presentations and pitches',
            'Proficiency in translating financial jargon into accessible language for clients',
            'Strong negotiation skills with a track record of closing high-stakes deals',
            'Ability to maintain composure and deliver under pressure, like spitting bars in a cypher'
          ]
        },
        {
          title: 'Financial Intelligence Analyst',
          description: 'We\'re looking for a sharp-minded individual to inspect and analyze market trends, inspired by Inspectah Deck\'s keen observations and analytical skills.',
          qualifications: [
            'Proven ability to dissect complex financial data and extract meaningful insights',
            'Experience in predictive modeling and trend analysis',
            'Proficiency in advanced data visualization techniques',
            'Strong problem-solving skills with attention to detail',
            'Ability to "bomb atomically" through financial reports and market research',
            'Expertise in identifying potential risks and opportunities in the market'
          ]
        },
        {
          title: 'Silent Assassin Portfolio Manager',
          description: 'Inspired by Masta Killa\'s quiet yet impactful presence, we seek a portfolio manager who can silently but effectively grow our clients\' wealth.',
          qualifications: [
            'Proven track record in managing diverse investment portfolios',
            'Ability to execute strategies with precision and discretion',
            'Strong analytical skills with a focus on long-term, sustainable growth',
            'Experience in alternative investments and emerging markets',
            'Proficiency in risk management and asset allocation',
            'Calm demeanor under pressure, able to make critical decisions in volatile markets'
          ]
        }
      ]
    }
  }
}
</script>
