<template>
  <div class="about bg-gradient-to-r from-black to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <h2 class="text-4xl font-bold text-white mb-8">The W: Our Financial Clan</h2>
      <p class="text-xl text-gray-300 mb-12">Our team of financial warriors brings the ruckus to your financial planning.</p>
      
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="member in clanMembers" :key="member.name" class="bg-white rounded-xl shadow-md overflow-hidden">
          <div class="p-8">
            <img :src="`images/${member.image}`" :alt="member.name" class="w-32 h-32 object-cover rounded-full mx-auto mb-4">
            <h3 class="text-2xl font-bold text-gray-900 text-center">{{ member.name }}</h3>
            <p class="text-gray-600 text-center">{{ member.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  data() {
    return {
      clanMembers: [
        { name: 'Robert F. Diggs', title: 'Chief Executive Officer', image: 'rza.jpg' },
        { name: 'Gary E. Grice', title: 'Chief Financial Officer', image: 'gza.jpg' },
        { name: 'Clifford Smith, Jr.', title: 'Chief Marketing Officer', image: 'methodman.jpg' },
        { name: 'Dennis D. Coles', title: 'Head of Risk Management', image: 'ghostface-killah.jpg' },
        { name: 'Corey Woods', title: 'Investment Strategist', image: 'raekwon.jpg' },
        { name: 'Russell T. Jones', title: 'Disruptive Innovation Specialist', image: 'odb.jpg' },
      ]
    }
  }}
</script>