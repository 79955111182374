<template>
  <footer class="wu-footer">
    <div class="wu-footer-content">
      <div class="wu-footer-left">
        <h3 class="wu-footer-title">Wu-Tang Financial</h3>
        <p class="wu-footer-text">Protecting your neck since 1992</p>
      </div>
      <div class="wu-footer-right">
        <nav>
          <router-link to="/privacy-policy" class="wu-footer-link">The Policies</router-link>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection'
}
</script>
