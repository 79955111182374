<template>
  <div class="home">
    <HeroSection />
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue'

export default {
  name: 'HomePage',
  components: {
    HeroSection
  }
}
</script>