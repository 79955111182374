<template>
  <div id="app">
    <NavigationMenu />
    <router-view></router-view>
    <FooterSection />
  </div>
</template>

<script>
import NavigationMenu from './components/NavigationMenu.vue'
import './assets/styles/main.css'
import FooterSection from './components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    NavigationMenu,
    FooterSection
  }
}
</script>
